import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
import HeroImg from "../../components/HeroImg/HeroImg"
import MainLayout from "../../layouts/MainLayout"
import "./home.scss"

const Home = a => {
  const { t } = useTranslation()

  return (
    <MainLayout metaTitle={t("home:metaTitle")}>
      <>
        <HeroImg customClass="home-hero" backgroundImage="bg/home-hero.jpg">
          {
            <div className="home-position-text">
              <img
                src="/img/gorda-logo.svg"
                className="gorda-logo"
                alt="logo"
              />
              <h1 className="hero-title">{t("home:heroTitle")}</h1>
              <h2 className="home-subtitle">{t("home:heroSubtitle")}</h2>
              <Link to={t("routes:ourBrandies")}>
                <button className="btn">{t("home:ourSpirits")}</button>
              </Link>
            </div>
          }
        </HeroImg>

        <div className="home-section-two">
          <h3>{t("home:sectionTwoTitle")}</h3>
          <h3 className="box-three">{t("home:sectionTwoTitle2")}</h3>

          <p>{t("home:copy1")}</p>
          <p className="box">{t("home:copy2")}</p>

          <h4>{t("home:sectionThreeTitle")}</h4>
          <h4 className="box">{t("home:sectionThreeSubTitle")}</h4>

          <p>{t("home:sectionFourTitle")}</p>
        </div>
      </>
    </MainLayout>
  )
}

export default Home
